import { IProject } from '@api/models/project.model';
import { IUser } from '@api/models/user.model';
import { Switch } from '@library/forms';
import { List, ListItem } from '@library/list';
// import { CircularProgress } from '@library/loaders/components';
import { useQueryClient } from '@tanstack/react-query';
import { Form, Formik } from 'formik';
import { produce } from 'immer';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import useProjectHierarchySelection from 'modules/shared/hooks/use-project-hierarchy-selection';
import { FC, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, CircularProgress, Drawer, LinearProgress, NavTab } from '@hyperflake/react-ui-library';
import useProjectAccessForm from 'modules/users/hooks/useProjectAccessForm';
import { PermissionEnum } from '@api/enum/permission.enum';
import ExternalProjectList from '../ExternalProjectList/ExternalProjectList';

interface IProjectAccessListFormProps {}

interface projectAccessForm {
    projects: string[];
}

enum TabEnum {
    NORMAL_PROJECTS,
    EXTERNAL_PROJECTS,
}

const ProjectAccessListForm = (props: IProjectAccessListFormProps) => {
    // const { userId, onClose, open } = props;
    const { clientId, userId } = useParams();
    const queryClient = useQueryClient();
    const [activeTab, setActiveTab] = useState(TabEnum.NORMAL_PROJECTS);

    const { data, isLoading, user, userLoading, submit, isSubmitting } = useProjectAccessForm(clientId, userId);

    const projects = useMemo(() => {
        if (!data) return [];
        return data;
    }, [data]);

    const { hasPermission } = useAuthPermission();

    const initialValues: projectAccessForm = {
        projects: user?.projects
            ? user.projects.map((project) => (typeof project === 'string' ? project : project._id))
            : [],
    };
    const onUserUpdate = (data: IUser) => {
        queryClient.setQueryData<IUser[]>(['clients', clientId, 'users'], (tempData) => {
            if (!tempData) return;

            const index = tempData.findIndex((user) => userId === user._id);
            if (index < 0) return tempData;

            return produce(tempData, (draft) => {
                draft[index] = data;
                return draft;
            });
        });
    };

    const handleSubmit = async (projectList: projectAccessForm) => {
        try {
            const data = await submit(projectList);
            toast.success(`${user?.name}'s project access has been updated.`);
            onUserUpdate(data);
            // onClose();
        } catch (e: any) {
            console.log(e);
            toast.error('some thing went wrong');
        }
    };

    return data?.length === 0 ? (
        <div>
            <>
                <div className="">
                    <h4>Project Access</h4>
                    <div className="mt-4 text-base font-semibold">
                        Manage {user ? user.name : userId}'s project access.
                    </div>
                </div>
                <div className="flex flex-col justify-center items-center h-full">
                    <div className="text-lg">This client has no project at the moment.</div>
                </div>
            </>
        </div>
    ) : (
        <div>
            <div>
                <div className="mt-4 text-base font-semibold">Manage {user ? user.name : userId}'s project access.</div>
            </div>
            <div className="">
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    validateOnChange={false}
                    onSubmit={handleSubmit}
                >
                    {({ setFieldValue, values }) => (
                        <Form>
                            <fieldset disabled={!hasPermission(PermissionEnum.USER_UPDATE)}>
                                <div className="mt-4 h-72 overflow-y-auto">
                                    <ProjectList
                                        projects={projects}
                                        selectedIds={values.projects}
                                        onSelectedIdsChange={(updatedProjects) =>
                                            setFieldValue('projects', updatedProjects)
                                        }
                                    />
                                </div>

                                <Button type="submit" disabled={isSubmitting} size={'lg'} className="mt-8 w-28">
                                    {isSubmitting ? <CircularProgress size={20} color={'inherit'} /> : 'Save'}
                                </Button>
                            </fieldset>
                        </Form>
                    )}
                </Formik>
            </div>
        </div>
    );
};

interface IProjectList {
    projects: IProject[];
    selectedIds: string[];
    onSelectedIdsChange: (projectIds: string[]) => void;
}

const ProjectList = (props: IProjectList) => {
    const { projects, selectedIds, onSelectedIdsChange } = props;

    const projectHierarchy = useProjectHierarchySelection({
        projects: projects,
        onSelectedIdsChange,
        selectedIds,
    });

    const sortProjectHierarchical = () => {
        let arrangedArray: any = [];

        const handleChildren = (id: string) => {
            if (!projectHierarchy.hasChildren(id)) return;
            projectHierarchy.getChildren(id).forEach((proj) => {
                arrangedArray.push(proj);
                handleChildren(proj._id);
            });
        };

        const grandParentList = projectHierarchy.getChildren('root');
        if (!grandParentList) return arrangedArray;
        grandParentList.forEach((grandProject) => {
            arrangedArray.push(grandProject);
            handleChildren(grandProject._id);
        });
        return arrangedArray;
    };

    const projectList = sortProjectHierarchical();

    return (
        <List divider>
            {projectList.map((row: IProject) => {
                return (
                    <ListItem
                        className="grid grid-cols-12 gap-2 my-1  border border-primary/20 rounded-md"
                        style={{ marginLeft: `${row.hierarchyLevel * 10}px` }}
                        key={row._id}
                    >
                        <div
                            className="col-span-10 flex flex-col py-2"
                            // style={{ marginLeft: `${row.hierarchyLevel * 10}px` }}
                        >
                            <div className="text-xs">{row._id}</div>
                            <div className="text-base">{row.name}</div>
                        </div>
                        <div className="col-span-1">
                            <Switch
                                checked={projectHierarchy.isSelected(row._id)}
                                onChange={(e) => projectHierarchy.changeSelected(row, e.target.checked)}
                            />
                        </div>
                    </ListItem>
                );
            })}
        </List>
    );
};
export default ProjectAccessListForm;
