import { PermissionEnum } from '@api/enum/permission.enum';
import { ICamera, ICameraCCTV } from '@api/models';
import useAuthPermission from '@hooks/use-auth-permission';
import { EllipsisHorizontalIcon } from '@icon/index';
import { Dropdown, DropdownItem, DropdownList, DropdownTrigger } from '@library/dropdown';
import { GalleryCard, Img } from 'modules/shared/features/content';
import moment from 'moment';

interface ICCtvCameraCard {
    cctvCamera: ICameraCCTV;
    onDeleteClick: (camera: ICameraCCTV) => void;
    onEditClick: () => void;
    hasDeletePermission: boolean;
}

const CctvCameraCard = (props: ICCtvCameraCard) => {
    const { cctvCamera, onEditClick, hasDeletePermission, onDeleteClick } = props;
    const { hasPermission } = useAuthPermission();
    const isOlderThan3Days = (date: string) => {
        return moment().diff(moment(date, 'YYYYMMDD'), 'days') <= 3;
    };

    return (
        <GalleryCard>
            <GalleryCard.ActionBar>
                {hasPermission(PermissionEnum.LIVE_CAMERA_UPDATE) && (
                    <div>
                        <Dropdown placement="bottom-end">
                            <DropdownTrigger className="w-6 h-6 grid place-items-center rounded-[4px] bg-floating-color image-overlay-button-shadow hover-transition hover:brightness-90">
                                <EllipsisHorizontalIcon className="text-grayscale-600" />
                            </DropdownTrigger>

                            <DropdownList className="min-w-[100px]">
                                <DropdownItem onClick={onEditClick}>Edit</DropdownItem>
                                {hasPermission && (
                                    <DropdownItem onClick={() => onDeleteClick(cctvCamera)} className="text-danger">
                                        Delete
                                    </DropdownItem>
                                )}
                            </DropdownList>
                        </Dropdown>
                    </div>
                )}
            </GalleryCard.ActionBar>

            <GalleryCard.Media>
                <Img
                    className="w-full aspect-image rounded-lg object-cover object-center"
                    src={cctvCamera.latestImage?.url}
                />
            </GalleryCard.Media>

            <GalleryCard.Title>{cctvCamera.name}</GalleryCard.Title>

            <GalleryCard.Footer>
                <div className="flex flex-wrap gap-4 justify-between">
                    {/* <GalleryCard.FooterItem
                        label="Last Image"
                        content={
                            cctvCamera?.installationDate ? (
                                <div
                                    className={
                                        isOlderThan3Days(cctvCamera?.installationDate)
                                            ? 'text-green-500'
                                            : 'text-red-500'
                                    }
                                >
                                    {moment(cctvCamera?.installationDate, 'YYYYMMDDHHmmss').format('h:mm A DD MMM, YY')}
                                </div>
                            ) : (
                                '-'
                            )
                        }
                    ></GalleryCard.FooterItem> */}
                    <div></div>

                    <GalleryCard.FooterItem
                        label="Installed On"
                        content={moment(cctvCamera?.installationDate, 'YYYYMMDD').format('DD MMM, YYYY')}
                    ></GalleryCard.FooterItem>
                </div>
            </GalleryCard.Footer>
        </GalleryCard>
    );
};

export default CctvCameraCard;
