import { UserRoleEnum } from '@api/enum';
import { PermissionEnum } from '@api/enum/permission.enum';

export const ADMIN_PERMISSIONS = Object.values(PermissionEnum);

export const EDITOR_PERMISSIONS = [
    PermissionEnum.PROJECT_READ,
    PermissionEnum.PROJECT_UPDATE,
    PermissionEnum.CLIENT_READ,
    PermissionEnum.CLIENT_UPDATE,
    PermissionEnum.ALL_USERS_READ,
    PermissionEnum.ALL_USERS_UPDATE,
    PermissionEnum.USER_READ,
    PermissionEnum.USER_UPDATE,
    PermissionEnum.SUPPORT_READ,
    PermissionEnum.SUPPORT_UPDATE,
    PermissionEnum.DRONE_READ,
    PermissionEnum.DRONE_UPDATE,
    PermissionEnum.RESELLER_CAMERA_READ,
    PermissionEnum.RESELLER_CAMERA_UPDATE,
    PermissionEnum.LIVE_CAMERA_READ,
    PermissionEnum.LIVE_CAMERA_UPDATE,
    PermissionEnum.CONSOLE_USER_READ,
    PermissionEnum.CONSOLE_USER_UPDATE,
    PermissionEnum.SIGN_IN_LOGS_READ,
    PermissionEnum.SIGN_IN_LOGS_UPDATE,
    PermissionEnum.SITE_GALLERY_READ,
    PermissionEnum.SITE_GALLERY_UPDATE,
    PermissionEnum.TIMELAPSE_CAMERA_READ,
    PermissionEnum.TIMELAPSE_CAMERA_UPDATE,
    PermissionEnum.PUBLIC_API_REQUEST_READ,
    PermissionEnum.PUBLIC_API_REQUEST_UPDATE,
];

export const VIEWER_PERMISSIONS = [
    PermissionEnum.PROJECT_READ,
    PermissionEnum.CLIENT_READ,
    PermissionEnum.ALL_USERS_READ,
    PermissionEnum.USER_READ,
    PermissionEnum.SUPPORT_READ,
    PermissionEnum.DRONE_READ,
    PermissionEnum.RESELLER_CAMERA_READ,
    PermissionEnum.LIVE_CAMERA_READ,
    PermissionEnum.CONSOLE_USER_READ,
    PermissionEnum.SIGN_IN_LOGS_READ,
    PermissionEnum.SITE_GALLERY_READ,
    PermissionEnum.TIMELAPSE_CAMERA_READ,
    PermissionEnum.PUBLIC_API_REQUEST_READ,
];

export const PermissionMap = {
    [UserRoleEnum.ADMIN]: ADMIN_PERMISSIONS,
    [UserRoleEnum.EDITOR]: EDITOR_PERMISSIONS,
    [UserRoleEnum.VIEWER]: VIEWER_PERMISSIONS,
};
