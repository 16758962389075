import { Button } from '@hyperflake/react-ui-library';
import { UploadIcon } from '@icon/index';
import { FC, useEffect, useRef, useState } from 'react';

interface UploadImageButtonProps {
    onFileChange: (file: File) => void;
    hasPermission?: boolean;
}

const UploadImageButton = (props: UploadImageButtonProps) => {
    const { onFileChange, hasPermission = true } = props;

    const fileRef = useRef(null);
    const [value, setValue] = useState(Date.now());

    useEffect(() => {
        if (!fileRef.current) return;

        fileRef.current.value = '';
    }, [value]);

    const handleChange = async (e: any) => {
        const files = e.target.files;

        if (files && files.length > 0) {
            onFileChange(files[0]);

            setValue(Date.now());
        }
    };

    return (
        <Button variant="shaded" size="sm" className="relative" disabled={!hasPermission}>
            <input
                ref={fileRef}
                type="file"
                className="absolute inset-0 w-full h-full opacity-0"
                onChange={handleChange}
                accept="image/png,image/jpg,image/jpeg"
                disabled={!hasPermission}
            />
            <UploadIcon width="12" height="14" /> Upload Image
        </Button>
    );
};

export default UploadImageButton;
