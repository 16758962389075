import { FC, useState } from 'react';
import http from 'httpConfig/http';
import { IClient } from '@api/models';
import { Label } from '@library/forms';
import { toast } from 'react-toastify';
import { Avatar } from '@library/avatar';
import { useMutation } from '@tanstack/react-query';
import DisplayImageView from '../ui/DisplayImageView';
import UploadImageButton from '../ui/UploadImageButton';
import useUploadProgress from 'modules/shared/hooks/use-upload-progress';

import { getResellerIdFromRedux } from 'modules/shared/helpers/shared.utils';
import { PermissionEnum } from '@api/enum/permission.enum';
import useAuthPermission from '@hooks/use-auth-permission';

interface IEmailHeaderForm {
    onEmailHeaderLogoUrlUpdate: (emailHeaderLogoUrl: string) => void;
    client: IClient;
}

const EmailHeaderForm: FC<IEmailHeaderForm> = (props) => {
    const { onEmailHeaderLogoUrlUpdate, client } = props;
    const { hasPermission } = useAuthPermission();

    const clientLogoUpdateMutation = async (
        payload: FormData,
        config: { onUploadProgress?: (progress: number) => void }
    ) => {
        const { data } = await http.put(
            `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${client._id}/email-header-logo`,
            payload,
            {
                onUploadProgress: (progressEvent) => {
                    const progress = (progressEvent.loaded / progressEvent.total) * 100;

                    config?.onUploadProgress?.(Math.trunc(progress));
                },
            }
        );

        return data as Pick<IClient, 'emailHeaderLogoUrl'>;
    };
    const updateLogoMutation = useMutation((payload: FormData) =>
        clientLogoUpdateMutation(payload, {
            onUploadProgress: (progress) => {
                setUploadProgress(progress);
            },
        })
    );
    const removeLogoMutation = useMutation(async () => {
        await http.delete(
            `${import.meta.env.VITE_APP_API_URL}/${getResellerIdFromRedux()}/clients/${client._id}/email-header-logo`
        );
    });

    const { uploadProgress, setUploadProgress } = useUploadProgress(client.emailHeaderLogoUrl);

    const handleClientEmailHeaderLogoUpdate = async (file: File) => {
        try {
            const formData = new FormData();
            formData.append('file', file);
            const { emailHeaderLogoUrl } = await updateLogoMutation.mutateAsync(formData);
            onEmailHeaderLogoUrlUpdate(emailHeaderLogoUrl);
            toast.success(' Email header logo updated successfully');
        } catch (err: any) {
            toast.error(err.data.message || 'Some error occured');
            console.log(err);
        }
    };

    const handleEmailHeaderLogoRemove = async () => {
        try {
            await removeLogoMutation.mutateAsync();
            toast.success('Email Header logo removed successfully');
            onEmailHeaderLogoUrlUpdate(null);
        } catch (err: any) {
            toast.error(err.data.message || 'Some error occured');
            console.log(err);
        }
    };

    return (
        <div>
            <Label>Email Header</Label>
            <div className="flex  items-center gap-2">
                <DisplayImageView
                    showRemove={Boolean(client.emailHeaderLogoUrl) && hasPermission(PermissionEnum.CLIENT_UPDATE)}
                    onRemove={handleEmailHeaderLogoRemove}
                    isUploading={updateLogoMutation.isLoading || removeLogoMutation.isLoading}
                    uploadProgress={uploadProgress}
                >
                    <Avatar
                        src={client.emailHeaderLogoUrl}
                        alt={client.name}
                        bgColor={client.preferences.primaryColor}
                        size={88}
                    />
                </DisplayImageView>

                <div className="flex flex-col items-center">
                    <UploadImageButton
                        onFileChange={handleClientEmailHeaderLogoUpdate}
                        hasPermission={hasPermission(PermissionEnum.CLIENT_UPDATE)}
                    />
                    <div className="text-xs font-medium text-grayscale-400 mt-2">Max size up to 10MB</div>
                </div>
            </div>
        </div>
    );
};

export default EmailHeaderForm;
