import { IClient } from '@api/models/client.model';
import { UploadIcon } from '@icon/index';
import { produce } from 'immer';
import useAuthPermission from 'modules/shared/hooks/use-auth-permission';
import { FC } from 'react';
import { toast } from 'react-toastify';

import { Button, CircularProgress } from '@hyperflake/react-ui-library';
import useClientLoginImageForm from 'modules/clients/hooks/useClientLoginImageForm';
import { Img } from 'modules/shared/features/content';
import { DeleteIcon, DeleteShapeIcon } from 'modules/shared/icons';
import { PermissionEnum } from '@api/enum/permission.enum';

interface IClientLoginImagesForm {
    client: IClient;
    onClientUpdate: (updatedClient: IClient) => void;
}

const ClientLoginImagesForm = (props: IClientLoginImagesForm) => {
    const { client, onClientUpdate } = props;

    const { hasPermission } = useAuthPermission();
    const clientLoginImageForm = useClientLoginImageForm(client);

    const handleImageDelete = async (imageId: string) => {
        if (!hasPermission(PermissionEnum.CLIENT_UPDATE)) {
            toast.error('only Admins are authorized to remove image');
            return;
        }

        const prevClient = produce(client, (draftState) => draftState);

        try {
            const updatedClient = produce(client, (draftState) => {
                const index = draftState.preferences.loginImages.findIndex((row) => row._id === imageId);

                if (index !== -1) {
                    draftState.preferences.loginImages.splice(index, 1);
                }

                return draftState;
            });

            onClientUpdate(updatedClient);

            await clientLoginImageForm.removeImage(imageId);

            toast.success('Login images removed successfully.');
        } catch (err: any) {
            console.log(err);

            onClientUpdate(prevClient);

            toast.error(err?.data?.message || 'Some error occured, please try again.');
        }
    };

    const handleImageUpload = async (file: File) => {
        if (!hasPermission(PermissionEnum.CLIENT_UPDATE)) {
            toast.error('Only Admins are authorized to upload image');
            return;
        }

        const formData = new FormData();

        formData.append('file', file);

        try {
            const newImage = await clientLoginImageForm.uploadImage(formData);
            console.log('newImage', newImage);

            const updatedClient = produce(client, (draftState) => {
                draftState.preferences.loginImages.push(newImage);
            });

            onClientUpdate(updatedClient);

            toast.success('Image uploaded successfully!');
        } catch (ex: any) {
            toast.error(ex?.data?.message || 'Some error occured, please try again.');

            console.log(ex);
        }
    };

    return (
        <div>
            <div className="grid grid-cols-4 gap-4 mb-4 empty:hidden">
                {client?.preferences.loginImages.map((loginImage) => (
                    <div key={loginImage._id} className="col-span-1 relative rounded-md overflow-hidden">
                        <Img src={loginImage.url} className="w-full h-full aspect-square object-cover object-center" />

                        <button
                            className="absolute right-2 top-2 w-6 h-6 grid place-items-center bg-card-color rounded-md text-grayscale-500"
                            onClick={() => handleImageDelete(loginImage._id)}
                        >
                            <DeleteIcon />
                        </button>
                    </div>
                ))}
            </div>

            <div className="relative flex justify-center cursor-pointer bg-primary/5 py-6 rounded-lg border border-dashed">
                {clientLoginImageForm.uploadImageLoading || clientLoginImageForm.removeImageLoading ? (
                    <CircularProgress size={20} color={'inherit'} />
                ) : (
                    <Button
                        variant={'shaded'}
                        size={'xs'}
                        className="cursor-pointer "
                        disabled={hasPermission(PermissionEnum.CLIENT_UPDATE) ? false : true}
                    >
                        <UploadIcon className="cursor-pointer" /> Upload Image
                    </Button>
                )}

                <input
                    type="file"
                    disabled={hasPermission(PermissionEnum.CLIENT_UPDATE) ? false : true}
                    className="absolute z-20 top-0 left-0 bottom-0 right-0 w-full h-full opacity-0 cursor-pointer"
                    onChange={(event) => {
                        const files = event.target.files;

                        if (files && files.length > 0) {
                            handleImageUpload(files[0]);
                        }
                    }}
                />
            </div>
        </div>
    );
};

export default ClientLoginImagesForm;
